/* const getEnumValuesbyClass = (classReference) => {
  return Object
    // eslint-disable-next-line new-cap
    .keys(new classReference())
    .reduce((acc, key) => ({ ...acc, [key]: key }), {})
} */

export const PrognosisStatus = Object.freeze({
  NoData: 'NoData',
  NewData: 'NewData',
  Run: 'Run',
  Ready: 'Ready', // Only on Frontend
  NoNewData: 'NoNewData'
})

export const CreationMethod = Object.freeze({
  Generated: 'Generated',
  Uploaded: 'Uploaded',
  Manual: 'Manual'
})

export const ScheduleState = Object.freeze({
  NotSubmitted: 'NotSubmitted',
  Submitted: 'Submitted',
  WaitingForSubmit: 'WaitingForSubmit',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  AcceptanceSuccessful: 'AcceptanceSuccessful'
})

export const MasterdataTab = Object.freeze({
  GroupResponsible: 'group-responsible',
  BalanceResponsible: 'balance-responsible',
  PowerPlant: 'powerplant',
  PowerPlantGroupGlobal: 'powerplant-group-global',
  PowerPlantGroup: 'powerplant-group',
  SolarPanel: 'solarpanel',
  Inverter: 'inverter'
})

export const View = Object.freeze({
  All: 'all',
  Day: 'day',
  Hour: 'hour'
})

export const ModalStatus = Object.freeze({
  Create: 'Create',
  Edit: 'Edit',
  Duplicate: 'Duplicate',
  Hidden: 'Hidden'
})

export const EstimateType = Object.freeze({
  DayAhead: 'DayAhead',
  Intraday: 'Intraday'
})

export const EstimateOptionType = Object.freeze({
  DayAhead: 'DayAhead',
  Intraday: 'Intraday',
  FarAhead: 'FarAhead'
})

export const EtrSyncError = Object.freeze({
  InvalidLastFactProductionData: 'InvalidLastFactProductionData',
  MissingCalculatedAvailabilityData: 'MissingCalculatedAvailabilityData',
  MissingPlannedAvailabilityData: 'MissingPlannedAvailabilityData',
  InvalidCalculatedAvailabilityData: 'InvalidCalculatedAvailabilityData',
  InvalidPlannedAvailabilityData: 'InvalidPlannedAvailabilityData',
  PowerPlantCoordinatesMissing: 'PowerPlantCoordinatesMissing'
})

export const EstimateError = Object.freeze({
  NoError: 'NoError',
  NoWeatherData: 'NoWeatherData',
  NoNewData: 'NoNewData',
  ValidationError: 'ValidationError',
  TechnicalMaximumExceeded: 'TechnicalMaximumExceeded',
  MissingWeatherData: 'MissingWeatherData',
  MissingProductionData: 'MissingProductionData',
  NoScheduleMetadata: 'NoScheduleMetadata',
  UnknownError: 'UnknownError',
  MissingMasterData: 'MissingMasterData',
  BackupWeatherDataUsed: 'BackupWeatherDataUsed'
})

export const ScheduleType = Object.freeze({
  DayAhead: 'DayAhead',
  Intraday: 'Intraday'
})

export const DataImportType = Object.freeze({
  Production: 'production',
  Weather: 'weather',
  Forecast: 'forecast',
  ProductionMkp: 'productionMkp',
  ScheduleMessage: 'scheduleMessage'
})

export const Unit = Object.freeze({
  Efficiency: 'efficiency', // kW
  Radiation: 'radiation', // W/m2
  Temperature: 'temperature', // °C
  Speed: 'speed' // km/
})

export const WeatherType = Object.freeze({
  Fact: 'fact',
  Estimate: 'estimate'
})

export const ListSortDirection = Object.freeze({
  Ascending: 'Ascending',
  Descending: 'Descending'
})

export const Role = Object.freeze({
  SysAdmin: 'sysadmin',
  Admin: 'admin',
  Operator: 'operator',
  Office: 'office'
})

export const JobTitleCode = Object.freeze({
  Operator: 'operator',
  Leader: 'leader',
  Office: 'office',
  Financial: 'financial',
  Sysoperator: 'sysoperator'
})

export const ScheduleUploadMekState = Object.freeze({
  Submitted: 'Submitted',
  NotSubmitted: 'NotSubmitted'
})

export const getEnumValues = (enumObj, long = false) => {
  return Object.values(enumObj).map((value) => ({
    code: `${value}${long ? '-long' : ''}`,
    value
  }))
}

export const getBoolValues = () => {
  return [true, false].map((value) => ({ code: value, value }))
}

export const PrognosisGenerateTab = Object.freeze({
  Manual: 'manual',
  Grouped: 'grouped'
})

export const PrognosisScheduleTab = Object.freeze({
  Manual: 'manual',
  Annual: 'annual'
})

export const ScheduleGenerateTab = Object.freeze({
  PowerPlant: 'powerplant',
  PowerPlantGroup: 'powerplant-group'
})

export const ScheduleManagementTab = Object.freeze({
  PowerPlant: 'powerplant',
  // PowerPlantGroup: 'powerplant-group',
  Finalized: 'finalized'
})

export const GroupEstimateStatus = Object.freeze({
  Complete: 'Complete',
  Incomplete: 'Incomplete',
  Scheduled: 'Scheduled',
  LastScheduled: 'LastScheduled'
})

export const AnalyzesTab = Object.freeze({
  Custom: 'custom',
  Group: 'group',
  LongTerm: 'longterm',
  BatchDownload: 'batch-download'
})

export const PrognosisSettingsTab = Object.freeze({
  Provider: 'provider'
})

export const NeuralLearningTab = Object.freeze({
  Learning: 'learning'
})

export const NeuralLearningMethods = Object.freeze({
  Default: 'Default',
  CustomModel: 'CustomModel',
  CustomModelWithNeural: 'CustomModelWithNeural'
})

export const MekhCategory = Object.freeze({
  V300: 'V300',
  V310: 'V310'
})

export const MekhStatus = Object.freeze({
  DisabledV310Tab: 'DisabledV310Tab',
  EnabledV310Tab: 'EnabledV310Tab',
  MissingSibling: 'MissingSibling',
  EnableCreate: 'EnableCreate',
  AlreadyExist: 'AlreadyExist'
})

export const KeyCodes = Object.freeze({
  Enter: 13,
  Esc: 27
})

export const InverterMonitoring = Object.freeze({
  Error: 'isError',
  Complete: 'isComplete',
  SemiComplete: 'isSemiComplete',
  NoData: 'NoData'
})

export const ForecastType = Object.freeze({
  ShortTerm: 'ShortTerm',
  LongTerm: 'LongTerm'
})
